@import '../variables.scss';

.ftitle {
  grid-area: title;
  padding-bottom: 22px;
  color: $sky;
  font: $ftitleFont;
  display: flex;
  justify-content: flex-start;
  align-self: flex-end;
}

.fcontacts {
  grid-area: contacts;
  display: flex;
  flex-direction: column;
}

$fC-fcopyrightFont: normal 1.2em $f2;

.fcopyright {
  grid-area: copyright;
  color: white;
}




.compressed {
  height: $fC-titleHeight!important;
}

.fC{
  &-contactItem {
    width: 100%;
    height: calc(100% - #{$fC-titleHeight});
    transition: height 0.5s;
    overflow-y: hidden;
    overflow-x: visible;
    margin-left: $fC-contactRowIconLeft;
    padding-left: -$fC-contactRowIconLeft;
    margin-right: -60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    background-clip: content-box;
    color: white;
  }


  &-contactTitle {
    position: relative;
    width: 100%;
    height: $fC-titleHeight;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 2px solid $grey;
    font: $fC-contactTitleFont;
    cursor: pointer;
    &Ar{
      top: calc(#{$fC-titleHeight}/2-#{$frame_arr_size}/2);
      right: -3*$frame_arr_size/2;    
    }
  }


  &-contactContent {
    height: calc(100% - #{$fC-titleHeight});
    width: 100%;
    display: flex;
    flex-direction: column;
    font: $fC-contactContentFont;
  }


  &-contactRow {
    position: relative;
    margin: $fC-contactRowMargin 0 0 0;
  }

  &-contactRowIconWrap {
    position: absolute;
    top: $fC-contactRowIconTop;
    left: $fC-contactRowIconLeft;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

  }

  
  
  &-contactRowIconBox {
    position: relative;
    height: $fC-contactRowIconLine;
    width: 25px;
    img{
      position: absolute;
      right: 0;
      bottom: 0;
    }
    
  }
  
}

.fcopyright {
  grid-area: copyright;
}