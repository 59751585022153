@import './projectCard.scss';
@import './projectsModal.scss';
@import './projectModalPhotoBlock.scss';
@import '../variables.scss';
@import './projectModalTextBlock.scss';

.projectsSection {
  width: 100%;
  height: $section_height;
  background: $pS-Cbg;
}

.disable {
  display: none!important;
}

.pS {
  &-construct {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template: "tb tb tb" $title-height "lab content rab" 1fr /1fr $construct_width 1fr ;
  }

  &-title {
    grid-area: tb;
    background-color: $pS-titleCbg;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $title-fontSize;
    font-family: $title-fontFamily;
    box-shadow: 0px 5px 13px -5px rgba(#000, .75);
  }

  &-projectsBlock {
    grid-area: content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

  &-projectsContent {
    display: grid;
    width: 100%;
    height: $pS-contentHeight;
    grid-template: 
    ". pcb ." 1fr/ 1fr $construct_width 1fr;
  }

  &-projectsCardsBlock {
    grid-area: pcb;
    position: relative;

  }

  &-L {
    position: absolute;
    top: calc(#{$pS-contentHeight}/2);
    left: -1*$frame_arr_size/2;    
  }
  
  &-R {
    position: absolute;
    top: calc(#{$pS-contentHeight}/2);
    right: -1*$frame_arr_size/2;
  }

  &-projectsLists {
    height: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 30px;
  }

  &-projectsList {
    width: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: $pS-labelTitleFont;
    background-color: $purple;
    color: white;
    cursor: pointer;
  }

}