@import '../variables.scss';
@import './contactsBlock.scss';

.footer {
  width: 100%;
  height: $footer-ContactsHeight+120px;
  background-color: $purple;
  margin-top: 10vh;
}

p {
  margin: 0;
}

.footer {

 

  &-construct {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: ". cont ." 1fr/ 1fr $construct_width 1fr;
  }

  &-content {
    grid-area: cont;
    display: grid;
    grid-template: "cb mb" 1fr/ 1fr 1fr;
  }

  &-contactsBlock {
    grid-area: cb;
    padding-right: $footer-CBPaddingRight;
    display: grid;
    grid-template: 
      "title" $footer-titleHeight
      "contacts" $footer-ContactsHeight
      "copyright" 1fr / 1fr;
  }

  &-mapBlock {
    grid-area: mb;
    padding-top: 75px;
    display: flex;
    justify-content: top;
    flex-direction: column;
  }
}





