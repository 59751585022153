@import '../variables.scss';

.advantagesSection {
  height: $section_height;
  width: 100%;
  background-image: url('/static/header_bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  p {
    font-family: $f2;
  }
  box-shadow: 0px 5px 13px -5px inset rgba(#000, .75),
  0px -5px 13px -5px inset rgba(#000, .75);
  
  
}

.aS {

  &-construct {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: 
      ". cont ." 1fr/ 1fr $construct_width 1fr;
    
  }

  &-content {
    position: relative;
    grid-area: cont;
    display: grid;
    grid-template: "tb . ab" 1fr/ calc(#{$aS-WS_width} - #{$aS-WS_left}) 1fr $aS-adv_block_width;
  }

  @media (min-height: 2000px) {
    &-content {
      position: relative;
      grid-area: cont;
      display: grid;
      grid-template: "tb" "ab" 1fr/ 1fr 1fr;
    }

    &-WSTextInfo {
      position: relative!important;
      top: 0;
      font-size: calc(#{$aS-WSTextInfo_fontSize}*0.7);
    }


  }

  &-WSTextBlock {
    grid-area: tb;
    position: relative;
  }

  &-WSTextInfo {
    z-index: 1;
    position: absolute;
    top: $aS-WSTextInfo_top;
    padding-right: $aS-WSTextInfo_paddingRight;
    font-size: $aS-WSTextInfo_fontSize;

    p:nth-child(2){
      margin-bottom: $aS-WSTextInfo_marginBottom;
    }
    p:nth-child(n+3){
      font-size: $aS-WSTextAnthem_fontSize;
    }
    p:nth-child(n+4){
      color: $indigo;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &-advBlock {
    grid-area: ab;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } 

  

  &-advItem {
    font: $aS-advItemFont;
    z-index: 1;
    margin: 9px 0 9px 0;
    width: 100%;
    height: $aS-advItem_height;
    box-shadow:inset 0 0 0 $aS-advItem_border $sky;
    border-radius: $aS-advItem_borderRadius;
    background-clip: border-box;
    box-sizing: border-box;
    display: grid;
    grid-template: 
      "icon text" 1fr/ #{$aS-advItem_height} 1fr;
  }

  &-advItemIcon {
    z-index: 2;
    grid-area: icon;
    background: $gr-lochPicton;
    border-radius: $aS-advItem_borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-advItemText {
    padding-left: 40px;
    color: $indigo;
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  &-contentGS {
    position: absolute;
    left: $aS-GS_left;
    top: $aS-GS_top;
    width: $aS-GS_width;
    height: $aS-GS_height;
    max-height: $aS-GS_maxheight;
    background-color: $contentGS-Cbg;
  }
  
  &-contentWS {
    display: flex;
    flex-direction: column-reverse;
    left: -$aS-WS_left;
  }
  
  &-contentWSimg {
    width: 100%;
  }



}