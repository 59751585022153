.homeIcon {
  display: flex;
  justify-content: center;
  align-self: center;
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  z-index: 10;
  transition: 0.5s;
  background-color: rgba($color: #000, $alpha: 0.3);
  &:hover {
    background-color: rgba($color: #000, $alpha: 0.8);
  }
  img {
    width: 70%;
    opacity: 0.5;
    transition: 0.5s;
    &:hover {
      opacity: 1;
    }
    box-sizing: border-box;

  }
}

.out {
  opacity: 0;
}