@import '../variables.scss';

$header-padding: 10px 0 10px 0;
$header-logoSize: 100px;




.header {
  width: 100%;
  height: $header_height;
  background-image: url('/static/header_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  padding: $header-padding;
  box-sizing: border-box;
  box-shadow: 0px -5px 7px -5px inset rgba(#000, .6)
}

.Rus {
  grid-area: rus;
  align-items: flex-end;
}
.Eng {
  grid-area: eng;
  align-items: flex-start;

}

.h {

  &-construct {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: ". con ."1fr/ 1fr $construct-width 1fr;
    
  }

  &-content {
    grid-area: con;
    height: 100%;
    display: grid;
    grid-template: "lb cb" 1fr/ 1fr 2fr;
    color: $header-Ctxt;

  }

  &-logoBlock {
    grid-area: lb;
    display: grid;
    grid-template: "logo title" 1fr/#{$header-logoSize} 1fr;
  }

  &-logo {
    grid-area: logo;
    background-image: url('/static/logo_colored.png');
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
  }

 
  
  &-titleBlock {
    font-size: $header-titleFontSize;
    font-family: $header-titlefontFamily;
    grid-area: title;
    display: grid;
    grid-template: "rus" 1fr "eng" 1fr;
  }

  &-title {
    display: flex;
    justify-content: right;
    margin: 3px 0 3px 0;
  }

  

  

  &-contactsBlock {
    grid-area: cb;
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    grid-template-rows: 40px 40px 1fr;
  }

  &-contact {
    scroll-snap-align: center;
    width: 100%;
    height: 100%;
    margin: 0 25px 0 25px;
    background-clip: content-box;
    display: grid;
    grid-template: "pb ab" 50px/4fr 5fr;
   
  }

  &-infoBlock {
    display: inline;
    overflow: visible;
  }

  &-contactsSwitch{
    display: grid;
    grid-template: ". desc . icon arrow" 1fr/1fr 200px 20px 50px  ;
    font-size: 1.2em;
    transition: 0.3s;


    &Name {
      grid-area: desc;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }

    &IconBlock {
      grid-area: icon;
      display: flex;
      justify-content: center;
      align-items: center;
      

    }

    &Arrow {
      display: flex;
      justify-content: center;
      align-items: center;

      &Img {
        width: 60%;
        height: 60%;
      }
    }

    &Icon {
      width: 70%;
      height: 60%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    &Block {
      margin-top: 10px;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 30px 30px 20px;

    }
  }

  &-contactsAdditional {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template: ". lic pres ." 1fr/ 1fr 80px 80px 1.5fr;
    font-size: 1em;
    margin: 5px;
    a, a:active, a:hover, a:visited {
      color: $header-Ctxt
    }
  }

  &-contactsLicence {
    grid-area: lic;
    cursor: pointer;
  }

  &-contactsPresentation {
    grid-area: pres;
    cursor: pointer;
  }

  &-phoneBlock, &-addressBlock {
    display: grid;
    grid-template: "icon subject" 1fr/ 40px  1fr;
  }

  &-phoneIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;

    }
  }

  &-addressIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 45%;

    }
  }

  &-phoneIcon &-addressIcon {
    grid-area: icon;
  }

  &-phone, &-address {
    grid-area: subject;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 15px 0 15px;
  }

  &-phone {
    font-size: 1.6em;

  }

  &-contact {
    font-size: 1.15em;
  }


}