@import './info.scss';
@import './employees.scss';
@import '../variables.scss';
@import './employeesDesc.scss';



.companySection {
  width: 100%;
  height: $cS-sec_height;
  background-image: url('/static/CompanySection/cS-background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}


.cS {
  &GS {
    top: 0!important;
  }

  &-WSconstruct {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template: ". contWS ." 1fr / 1fr 1200px 1fr
  }

  &-WSContentBorder {
    grid-area: contWS;
    position: relative;
  }
  
  &-construct {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: 
      "t t t" $title-height
      ". . ." $cS-gap_height
      "cont cont cont" $cS-cont_height
      ". bb ." $cS-bb_height
      ". . ." 1fr/ 1fr $construct_width 1fr;
    box-shadow: 0px -5px 17px -5px inset rgba(#000, .75);
  }

  &-title {
    z-index: 10;
    grid-area: t;
    background-color: $cS-TitleCbg;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $title-Ctxt;
    font-size: $title-fontSize;
    font-family: $title-fontFamily;
    box-shadow: 0px 5px 13px -5px rgba(#000, .75);
  }

  &-content {
    grid-area: cont;
    position: relative;
    display: grid;
    grid-template: 
    ". con ." 1fr/ 1fr $construct_width 1fr;
    
  }

  &-container{
    position: absolute;
    grid-area: con;
    transition: opacity 0.5s, transform .8s;
    width: 100%;
    height: 100%;
  }

  

  &-buttonsBlock {
    grid-area: bb;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

 
  &-buttons {
    z-index: 3;
    grid-area: cont;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: $cs-pb_width;

    
    .active {
      background: $cS-btnCbgA;
      color: white;
    }
  }

  &-button {
    margin: 0 auto 0 0;
    outline: none;
    border: 0px;
    border-radius: $cS-button_border_radius;
    background-color: $cS-btnCbg;
    color: $cS-btnCtxt;
    height: $cS-button_height;
    width: $cS-button_width;
    font: $cS-buttons_font;
  }



  
}