@import '../variables.scss';




.pM {
  &-frame{
    &Text{
      &Block{
        grid-area: tb;
        padding: 30px 0 0 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background-color: white;
      }

      &Table{
        overflow-y: scroll;
        grid-area: tb;
        padding: 30px 50px 30px 50px;
        display: grid;
        grid-auto-rows: min-content;
        grid-template-columns: 1fr;
        font: $pM-frameTextBlockFont;
        background-color: white;
        
      }

      &TableRow{
        position: relative;
        display: grid;
        grid-template: "mark title date" 35px/40px 1fr 100px;

      } 

      &TableCellTitle{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &TableCellDate{
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &Row {
        width: 100%;
        display: grid;
        grid-template: "mark text" 1fr/ 40px 1fr;
      }

      &MarkerBox {
        grid-area: mark;
        display: flex;
        align-items: flex-start;
        
        position: relative;
      }

      &Marker {
        box-sizing: border-box;
        width: 100%;
        height: 1.405em;
        margin: 0 5px 0 0;
        

      }

      &Text {
        display: block;
        grid-area: text;
        text-align: left;
        font: $pM-frameTextBlockFont;
      }
    }
  }
}