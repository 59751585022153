.pS {



  &-projectsCardBlock, &-projectsCardBlockOut, &-projectsCardBlockIn {
    margin: 0 $ps-CardsGap 0 $ps-CardsGap;
    height: $pS-CardSize;
    width: $pS-CardSize;
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s;
    
  }

  &-projectsCardBlockIn {
    z-index: 1;
  }

  &-projectsCardBlock {
    z-index: 2;
  }



  &-projectCard {
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  

  &-projectLabel, &-projectLabelUp {
    width: 100%;
    height: 400px;
    padding-bottom: 10px;
    position: absolute;

    background: rgba(0, 0, 0, 0.6);
    color: white;
    box-sizing: border-box;
    padding-left: $pS-projectLabelPadding;

    &Wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

  
    .lTitle {
      font: $pS-labelTitleFont;
      width: 100%;
      height: $pS-labelTitleHeight;
      display: flex;
      justify-content: flex-start;
      align-items: center;

    }

    .lDescItem {
      min-height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font: $pS-labelDescFont;
      .lDescTitle {
        text-transform: uppercase;
        text-align: center;
        vertical-align: center;
      }

      .lDesc{
        text-align: center;
        vertical-align: center;
      }      
    }
    .lButton{
      position: absolute;
      bottom: 90px;
      left: calc(50% - 75px);
      border-radius: 10px;
      border: 3px solid $pS-buttonBCbg;
      background-color: transparent;
      color: white;
      height: 40px;
      width: 150px;
      font: $pS-labelButtonFont;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .lButton:hover {
      background-color: rgba(#555, 0.5);
    }
  }

  &-projectLabel {
    bottom: 0px;
    transform: translateY(280px);
    transition: 0.2s ease-out;
  }

  &-projectLabelUp {
    bottom: -125px;
    transform: translateY(-75px);
    transition: 0.2s ease-in;
  }

}