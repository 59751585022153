@import '../variables.scss';

.ProjectsModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.pM {
  
  &-frame {
    
    position: relative;
    background-color: $purple;
    width: 1200px;
    display: grid;
    grid-template: "title" 64px "content" 554px/ 1fr ;

    &L {
      top: calc(#{$frame_height}/2 + #{$frame_title_height});
      left: -3*$frame_arr_size/2;    
    }
    
    &R {
      top: calc(#{$frame_height}/2 + #{$frame_title_height});
      right: -3*$frame_arr_size/2;
    }

    &Title {
      font: $frame-BoxTitleFont;
      grid-area: title;
      background: $pM-titleCbg;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 18pt;
      box-sizing: border-box;
      padding: 0 100px 0 100px;
      text-align: center;
    }

    &CloseBlockIcon {
      position: absolute;
      box-sizing: border-box;
      width: 64px;
      height: 64px;
      top: 0;
      right: -80px;
      background-color: rgba(#555, 0.5);
      border: 5px solid $loch;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 100%;
        height: auto;
      }
      &:hover {
        background-color:$picton;
      }
    }
    
    &Content {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template: "pb tb" 1fr/1fr 1fr;
    }

    &-mono {
      grid-template: "tb" 1fr/1fr!important;

      .pM-frameTextTableRow {
        position: relative;
        display: grid;
        grid-template: "mark title date" minmax(2.5em, 1fr)/40px 1fr 100px;
        padding-bottom: 0.5em;
      }

      .pM-frameTextTableValue {
        padding-right: 0.5em;
      }

    }
  }
}