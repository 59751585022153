@import '../variables.scss';
@import './modalLicense.scss';

.licenseSection {
  width: 100%;
  height: $section_height;
  background-image: url('/static/LicenseSection.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lS {
  
  &-construct {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: grid;
    grid-template: 
      "t t t" $title-height
      ". cont ." 1fr / 1fr $construct_width 1fr;
  }
 
  &-title {
    grid-area: t;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba($color: #000000, $alpha: 0.9);
    font-size: $title-fontSize;
    font-family: $title-fontFamily;
  }

  &-content {
    grid-area: cont;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 1fr;

  }

  &-block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

 

  &-pic {
    height: $A4_height*$A4_multiplier_small;
    width: $A4_width*$A4_multiplier_small;
    max-height: $A4_maxheight_small;
    max-width: $A4_maxheight_small*$A4_width/$A4_height;
    border: 1px solid black;
    background-color: white;
    object-fit: cover;
    transition: 0.3s;
    img {
      width: 100%;
      height: auto!important;
    }
    &:hover{
      z-index: 1;
      transform: scale(1.1);
    }

    
  }
}