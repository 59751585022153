@import '../variables.scss';
@import './serviceModal.scss';



.servicesSection {
  width: 100%;
  background-color: transparent;
  height: $section-height;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sS {

  &-construct {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: 
      "t t t" $title-height
      ". cont ." 1fr/ 1fr $construct_width 1fr;
    
  }

  &-title {
    color: white;
    grid-area: t;
    background-color: $sS-titleCbg;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $title-fontSize;
    font-family: $title-fontFamily;
    box-shadow: 0px 5px 13px -5px rgba(#000, .75);
  }

  &-content {
    grid-area: cont;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  &-serviceBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  &-serviceBox {
    width: $sS-BoxWidth;
    border-radius: $sS-BoxBorderRadius;
    border-color: $sS-boxCbg;
    height: $sS-BoxHeight;
    background-color: white;
    display: grid;
    grid-template: "icon title b" 1fr/ #{$sS-BoxIconWidth} 1fr #{$sS-BoxButtonWidth};
    overflow: hidden;
    box-sizing: border-box;

  }

  &-serviceIcon {
    grid-area: icon;
    border-radius: $sS-BoxBorderRadius;
    background-color: $sS-boxCbg;
    box-shadow: inset $sS-BoxShadow $green;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 90%;
      height: 90%;
      border: none;
    }
  }

  &-serviceTitle {
    grid-area: title;
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 0 $sS-BoxTitlePadding 0 $sS-BoxTitlePadding;
    text-align: left;
    text-transform: uppercase;
    font: $sS-BoxFont;
  }

  &-serviceButton {
    background-color: $purple;
    box-shadow: inset $sS-BoxShadow $purple;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      opacity: 1;
    }
  }

  

}