.ymap-container, .ymap-current, .ymap-pending {
  width: 600px;
  height: 350px;
}

.ymap-current {
  position: absolute;
  opacity: 1;
  transition: opacity 1s;
  z-index: 2;
}

.ymap-pending {
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
  z-index: -2;
}

