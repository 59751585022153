@import './variables.scss';

.arr {
  position: absolute;
  width: $frame_arr_size;
  height: $frame_arr_size;
  
  display: flex;
  justify-content: center;
  align-items: center;   
  cursor: pointer;   
}

.title {
  text-transform: uppercase;
  text-align: center;
}

.close {
  position: absolute;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  top: 0;
  right: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
    height: auto;
  }
}

.contentWS {
  position: absolute;
  height: 100%;
  width: $aS-WS_width;
  background-color: white;
  right: 0;
}

.contentGreyS {
  position: absolute;
  height: 100%;
  width: $aS-WS_width;
  background-color: $lightgrey;
  right: 0;
}

.contentWSimg {
  width: 100%;
}

.tcgreen {
  color: $indigo
}

.bggrey {
  background-color: $sky;
}

sup {
  line-height: 0em;
}

.tcblack {
  color: black;
}

.marker_e{
  background-color: transparent;
}
.marker_s1{
  background-color: $indigo;
}
.marker_s2{
  background-color: $grey;
}
.marker_area{
  margin-right: 0px!important;
  background-color: $sky;
}
.marker_c1{
  position: absolute;
  color: $grey;
  width: 1em!important;
  height: 1em!important;
  border-radius: 1em;
  border: 0.25em solid $grey;
  top: 0.2em;
  right: 0;
}

.marker_table {
  left: -1.5em!important
}