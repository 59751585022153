@import './variables.scss';

.fixedOverlay {
  z-index: -101;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.fixedBG {
  position: absolute;
  width: 100vw;
  height: $section_height;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}