@import '../variables.scss';
@import 'serviceTabs/Arch.scss';
@import 'serviceTabs/Urban.scss';
@import 'serviceTabs/Network.scss';
@import 'serviceTabs/Transport.scss';

.ServicesModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.sM {
  
  &-frame {
    
    position: relative;
    background-color: white;
    width: $construct_width;
    display: grid;
    grid-template: "title" 64px "content" 554px/ 1fr ;

    &L {
        top: calc(#{$frame_height}/2 + #{$frame_title_height});
        left: -3*$frame_arr_size/2;    
    }
    
    &R {
      top: calc(#{$frame_height}/2 + #{$frame_title_height});
      right: -3*$frame_arr_size/2;
    }

    $sM-TitlePadding: 100px;

    &Title {
      grid-area: title;
      background-color: $sM-titleCbg;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 18pt;
      box-sizing: border-box;
      padding: 0 $sM-TitlePadding 0 $sM-TitlePadding;
      text-align: center;
    }



    &CloseBlockIcon {
      position: absolute;
      box-sizing: border-box;
      width: $closeIconSize;
      height: $closeIconSize;
      top: 0;
      right: $closeIconRight;
      background-color: rgba(#555, 0.5);
      border: $closeIconBorder solid $sM-titleCbg;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 100%;
        height: auto;
      }
      &:hover {
        background-color: $sM-titleCbg;
      }
    }     
  }
}

/*

    
*/