@import '../variables.scss';


.navbar {
  position: sticky;
  z-index: 99;
  width: 100%;
  height:  $nav_height;
  top: 0;
  background-color: $nav-Cbg;
  font-size: $navbar-FontSize;
  font-family: $navbar-FontFamily;
}

.nVisDown {
  opacity: 0;
}

.n {
  &-construct {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: ". con ." 1fr/ 1fr 1200px 1fr;
  }

  &-content {
    grid-area: con;
    display: flex;
    justify-content: space-between;
    
  }

  &-tabContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  &-tab {
    margin: 0 40px 0 0;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    text-transform: uppercase;

    &Item {
      padding: 5px 0 5px 0;
    }

    &Item:hover {
      border-bottom:3px solid rgba($purple, $alpha: 0.8) ;
      border-top: 3px solid transparent;
      cursor: pointer;
    }
    &ItemIn {
      padding: 5px 0 5px 0;
      border-bottom:3px solid rgba($purple, 1) ;
      border-top: 3px solid transparent;
      cursor: pointer;
    }
  }

  &-phoneContent {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    transition: 0.5s;
  }

  &-phone {
    //color:  #312b36;
    font-size: 14pt;
  }

  &-smsk {
    width: 35px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 10px 0 30px;
  }

  &-spvl {
    width: 35px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 5px 0 15px;
  }
}