@import '../variables.scss';

.cS {

  &-info{

    &Content {
      position: relative;
      grid-area: con;
      display: grid;
      grid-template: "text pic" 1fr / 1fr 450px;
    }

    &ContentWS {
      height: calc(100vh - 2*#{$title-height})!important;
      top:  $title-height;
      right: -$aS-WS_left;
    }

    &Img{
      z-index: 1;
      grid-area: pic;
      position: relative;
      box-shadow: .6em .6em 2.0em 0 black;
      height: 504px;
      img{
        position: absolute;
        left: 0;
        top: 0;
        height:100%;
      }
    }

    $cS-infoTextPaddingRight: 100px;

    &Text{
      display: grid;
      grid-template: 
        "." 26px
        "text" 1fr / 1fr ;
      
      &Content {
        grid-area: text;
        margin: 50px 0 auto 0;
        grid-area: text;
        font: $cS-infoTextFont;
        color: $cS-infoCTxt;
        padding-right: $cS-infoTextPaddingRight;
        div {
          margin-bottom: 20px;
        }
      }
    }

  }
}