@import '../../variables.scss';

.sM {
  &-frame {
    &Grid {
      &Transport {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr 350px 1fr ;
        grid-template-columns: 1fr 500px 1fr;
        background-image: url('/static/Services/backgroundModal.png');
        * {
          display: grid;
          grid-template:
          "title" 79px
          "content" 1fr/1fr
        }
        &Box {          
          &1 {
            grid-area: 2/2/3/3;
          }
                  
          &Title {
            grid-area: title;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
            text-align: center;
            background: $sM-tabTitleCbg;
            color: white;
            font: $frame-BoxTitleFont;
          }
    
          &Content {
            grid-area: content;
            padding: 20px 0 20px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: white;            
          }
          
          &Row {
            width: 100%;
            display: grid;
            grid-template: "mark text" 1fr/ 25px 1fr;
          }
    
          &MarkerBox {
            grid-area: mark;
            display: flex;
            align-items: flex-start;
          }
    
          &Marker {
            display: block;
            box-sizing: border-box;
            margin: 6px 0px 0 0px;
            background-color: $sM-markerTitleCbg;
            width: 19px;
            height: 9px;
          }
    
          &Text {
            display: block;
            grid-area: text;
            text-align: left;
            font: $frame-BoxTextFont;
          }
        }        
      } 
    }
  }
}