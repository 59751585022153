//utils
@import './base.scss';
@import './modal.scss';
@import './features.scss';
@import './homeIcon.scss';

//BG
@import './fixedBG.scss';

//content
@import 'header/header.scss';
@import 'navbar/navbar.scss';
@import 'startSection/startSection.scss';
@import 'companySection/companySection.scss';
@import 'serviceSection/serviceSection.scss';
@import 'projectSection/projectSection.scss';
@import 'licenseSection/licenseSection.scss';
@import 'advantagesSection/advantagesSection.scss';
@import 'footer/ymap.scss';
@import 'footer/footer.scss';
@import 'fontsize.scss';

//Fonts

$font_url: '/static/Fonts/';
/*
$oT_con: 'MINT TYPE - OPINION PRO CONDENSED LIGHT.OTF';
$oT_conI: 'MINT TYPE - OPINION PRO CONDENSED LIGHTITALIC.OTF';
$oT_normal: 'MINT TYPE - OPINION PRO LIGHT.OTF';
$oT_normalI: 'MINT TYPE - OPINION PRO LIGHT ITALIC.OTF';
$oT_extracon: 'MINT TYPE - OPINION PRO EXTRACONDENSED LIGHT.OTF';
$oT_extraconI: 'MINT TYPE - OPINION PRO EXTRACONDENSED LIGHT ITALIC.OTF';
$oT_expanded: 'MINT TYPE - OPINION PRO EXTENDED LIGHT.OTF';
$oT_expandedI: 'MINT TYPE - OPINION PRO EXTENDED LIGHT ITALIC.OTF';
$tR_NN: 'TREBUC.TTF';
$tR_NB: 'TREBUCBD.TTF';
$tR_IN: 'TREBUCIT.TTF';
$tR_IB: 'TREBUCBI.TTF';
*/

$cP_N: 'Cuprum-Regular.ttf';
$cP_B: 'Cuprum-Bold.ttf';
$fS_N: 'FiraSans-Regular.ttf';
$fS_B: 'FiraSans-Bold.ttf';

@font-face {
  src: url($font_url + $cP_N);
  font-family: cp;
  font-weight: normal;  
}

@font-face {
  src: url($font_url + $cP_B);
  font-family: cp;
  font-weight: bold;  
}

@font-face {
  src: url($font_url + $fS_N);
  font-family: fs;
  font-weight: normal;  
}

@font-face {
  src: url($font_url + $fS_B);
  font-family: cp;
  font-weight: bold;  
}

/*


@font-face {
  src: url($font_url + $oT_con);
  font-family: ot;
  font-stretch: condensed;
  font-style: normal;
}

@font-face {
  src: url($font_url + $oT_conI);
  font-family: ot;
  font-stretch: condensed;
  font-style: italic;
}

@font-face {
  src: url($font_url + $oT_normal);
  font-family: ot;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  src: url($font_url + $oT_normalI);
  font-family: ot;
  font-stretch: normal;
  font-style: italic;
}

@font-face {
  src: url($font_url + $oT_extracon);
  font-family: ot;
  font-stretch: extra-condensed;
  font-style: normal;
}

@font-face {
  src: url($font_url + $oT_extraconI);
  font-family: ot;
  font-stretch: extra-condensed;
  font-style: italic;
}

@font-face {
  src: url($font_url + $oT_expanded);
  font-family: ot;
  font-stretch: expanded;
  font-style: normal;
}

@font-face {
  src: url($font_url + $oT_expandedI);
  font-family: ot;
  font-stretch: expanded;
  font-style: italic;
}

@font-face {
  src: url($font_url + $tR_NN);
  font-family: tr;
  font-style: normal;
  font-weight: normal;  
}

@font-face {
  src: url($font_url + $tR_IN);
  font-family: tr;
  font-style: italic;
  font-weight: normal;  
}

@font-face {
  src: url($font_url + $tR_NB);
  font-family: tr;
  font-style: normal;
  font-weight: bold;  
}

@font-face {
  src: url($font_url + $tR_IB);
  font-family: tr;
  font-style: italic;
  font-weight: bold;  
}

*/





