@import '../variables.scss';

.startSection {
  width: 100%;
  height: $sS-height;
  position: relative;

  &-image {
    & > * {
      height: 100%;
      width: 100%;
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  &-contentBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 7%;
    right: 210px;
    width: 700px;
    height: auto;
  }

  &-title {
    margin: 0 $sS-img_title_margin 0 $sS-img_title_margin;
    display: flex;
    justify-content: center;
    color: $startS-Ctxt;
  }

  .t1 {
    font-size: $sS-t1_fontSize;
    font-family: $sS-t1_fontFamily;
    font-weight: bold;
  }

  .t2 {
    font-size: $sS-t2_fontSize;
    font-family:  $sS-t2_fontFamily;
  }


  

}



/* 
  grid-template: 
    ". . ." 1.5*$nav_height
    ". cb ." 1fr
    ". . ." 40px / 50px 1fr 50px;


&-contentBlock {
    grid-area: cb;
    display: grid;
    grid-template:  
      "ci . pr" 1fr 
      "ci . ." 20px
      "ci . tp" 1fr/1fr 20px 1fr;
    border: 1px solid white;
  }

      &-current{
      opacity: 1;
      transition: opacity 1s;
    }
    &-pending{
      opacity: 0;
      transition: opacity 1s;

    }

  &-companyInfo {
    grid-area: ci;
    border: 1px solid white;
  }

  &-projects {
    grid-area: pr;
    border: 1px solid white;
  }

  &-types {
    grid-area: tp;
    border: 1px solid white;
  }
*/