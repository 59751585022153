$nav_height: 60px;
$header_height: 120px;
$empCount: 5;
$construct_width: 1200px;
$section-height: calc(100vh - #{$nav_height});
$closeIconSize: 64px;
$closeIconRight: -80px;
$closeIconBorder: 5px;
$arrSize: 30px;
$f1: fs;
$f2: cp;
 
//colors

$green: #0faa3b;
$green-gradient: linear-gradient(#0faa3b, #06531c);
$purple: #312b36;
$grey: #888;
$lightgrey: #dcdcdc;
$indigo: #320091;
$sky: #5ca3d8;
$gr-skyIndigo: linear-gradient($sky, $indigo);
$loch: #3C76A1;
$picton: #61A6D9;
$gr-lochPicton: linear-gradient($loch, $picton);

//colorsTarget
$nav-Cbg: $lightgrey;
$header-Cbg: white;
$header-Ctxt: black;
$startS-Ctxt: $indigo;
$cS-TitleCbg: $sky;
$title-Ctxt: white;
$cS-Cbg: white;
$contentGS-Cbg: $indigo;
$cS-infoCTxt: black;
$cS-btnCbgA: $gr-skyIndigo;
$cS-btnCbg: $grey;
$cS-btnCtxt: white;
$sS-titleCbg: $sky;
$sS-boxCbg: $sky;
$sS-arrBbg: $purple;
$sM-titleCbg: $purple;
$sM-tabTitleCbg: $gr-lochPicton;
$sM-markerTitleCbg: $indigo;
$pS-Cbg: $gr-skyIndigo;
$pS-titleCbg: $gr-lochPicton;
$pS-buttonBCbg: $sky;
$pM-titleCbg: $gr-lochPicton;
$pm-propsC: $sky;

//header
$header-titleFontSize: 1.3em;
$header-titlefontFamily: $f1;


//navbar
$navbar-FontSize: 1.65em;
$navbar-FontFamily: $f2;


//StartSection
$sS-height: calc(100vh - #{$nav_height} - #{$header_height});
$sS-img_title_margin: 10px;
$sS-t1_fontSize: 2.4em;
$sS-t1_fontFamily: $f1;
$sS-t2_fontSize: 1.8em;
$sS-t2_fontFamily: $f2;


//SectionTitle
$title-fontSize: 2.5em;
$title-height: 60px;
$title-fontFamily: $f2;

//CompanySection

$cS-sec_height: $section-height;
$cS-gap_height: minmax(50px, 1fr);
$cS-cont_height: minmax(300px, 500px);
$cS-bb_height: minmax(100px, 3fr);
$cs-tb_pos: calc(50% - 60px);
$cs-pb_widthN: 450;
$cs-pb_width: #{$cs-pb_widthN}px;
$cS-textInTop: 30px;
$cS-textInLeft: 30px;
$cS-button_height: 50px;
$cS-button_width: 150px;
$cS-button_border_radius: 10px;
$cS-buttons_font: normal 1.8em $f2;
$cS-infoTextFont: normal 1.55em $f2;

  //employees
  $cS-empArrSize: 30px;
  $cS-empPhotoMainWidthN: 270;
  $cS-empPhotoMainHeightN: 330;
  $cS-empPhotoBoxShadow: 10px;
  $cS-titleMarginBottom: 5px;
  $cS-nameFontSize: 1.9em;
  $cS-jobFontSize: 1.4em;

  //info

  $cS-textWidth: 450px;
  $cS-contentGridGap: 20px;


//ServiceSection

$sS-BoxWidth: 520px;
$sS-BoxHeight: 150px;
$sS-BoxBorderRadius: 20px;
$sS-BoxIconWidth: 150px;
$sS-BoxButtonWidth: 50px;
$sS-BoxShadow: 5px;
$sS-BoxTitlePadding: 15px;
$sS-BoxTitleSize: 13px;
$sS-BoxFont: normal 1.5em $f1;

//ProjectsSection
$pS-contentHeight: 350px;
$pS-CardSize: 350px;
$ps-CardsGap: $construct_width/5-3*$pS-CardSize/5;
$pS-projectLabelPadding: 10px;
$pS-labelTitleHeight: 60px;
$ps-labelFontSize: 60px;
$ps-fontSize: 10pt;
$pS-labelTitleFont: bold 1.6em $f2;
$pS-labelDescFont: normal 1.4em $f2;
$pS-labelButtonFont: normal 1.6em $f2;
$pS-photoProps: bold 1.4em $f2;

//LicenseSection 
$A4_height: 297px;
$A4_width: 210px;
$A4_multiplier_small: 0.83;
$A4_multiplier_big: 2.7;
$A4_maxheight_small: 34vh;
  //ModalLicense
  $mL-img_max_height: 90vh;

//AdvantagesSection
$aS-adv_block_width: 705px;
$aS-WS_width: 450px;
$aS-WS_left: 63px;
$aS-GS_left: calc(#{$construct_width} - 100vw);
$aS-GS_width: calc(100vw - #{$construct_width} + #{$aS-WS_width}/2 - #{$aS-WS_left});
$aS-GS_top: 6vh;
$aS-GS_height: 25px;
$aS-GS_maxheight: 4vh;
$aS-WSTextInfo_top: 15vh;
$aS-WSTextInfo_paddingRight: 50px;
$aS-WSTextInfo_fontSize: calc(18*100vh/746);
$aS-WSTextAnthem_fontSize: calc(21*100vh/746);
$aS-WSTextInfo_marginBottom: 5vh;
$aS-advItem_height: 65px;
$aS-advItem_border: 2px;
$aS-advItem_borderRadius: 5px;
$aS-advItemFont: bold 2em $f2;

//Footer
$footer-CBPaddingRight: 120px;
$footer-ContactsHeight: 700px;
$footer-MapHeight: 350px;
$footer-titleHeight: 75px;
$fC-contactTitleFont: bold 1.5em $f2;
$fC-contactContentFont: normal 1.5em $f2;
$ftitleFont: normal 1.9em $f1;
$fC-titleHeight: 60px;
$fC-contactRowMargin: 15px;
$fC-contactRowIconTop: 0px;
$fC-contactRowIconLeft: -30px;
$fC-contactRowIconLine: 16px;


//Modal
$frame_height: 554px;
$frame_width: 1200px;
$frame_title_height: 64px; 
$frame_content_gap: 30px;
$frame_arr_size: 30px;
$frame-titleFont: bold 20px $f1;
$frame-BoxTitleFont: bold 1.4em $f2;
$frame-BoxTextFont: normal 1.43em $f2;
  //pM
  $pM_frame_photo_height: 270px;
  $pM_frame_photo_gap: 30px;
  $pM_frame_props: 40px;
  $pM_frame_photo_width: 478px;
  $pM-frameTextBlockFont: normal 1.23em $f2;



:export {
  csPb_widthN: $cs-pb_widthN;
  navH: $nav_height;
}