@import './variables.scss';

body {
  width: 100vw;
  max-width:100%;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  font-family: fs, cp, sans-serif;
  font-size: 12px;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;
}



@media (max-width: 768px){
  body {
    width: 768px;
    max-width: 768px;
  }
  
}

@media (max-width: 1250px){
  #root {
    overflow-x: hidden;
  }
}

@supports (-webkit-touch-callout: none) {
  #root {
    width: 1296px;
    height: 729px;
  }
}

html {
  scroll-behavior: smooth;
  background-color: transparent;
}

div::-webkit-scrollbar, body::-webkit-scrollbar{
  width: 1em;
  border-radius: 1em;
  
}
 
div::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 6px rgba(#A3A3A3, 1);
  display: none;
}
 
div::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb{
  background-color: $indigo;
  
}