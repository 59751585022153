@import '../variables.scss';


.pM {
  
  &-frame {
    &Photo{
      position: relative;
      grid-area: photo;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s;
      
      &Block{
        grid-area: pb;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &Content{
        display: grid;
        grid-template: 
          "photo" $pM_frame_photo_height
          "." $pM_frame_photo_gap 
          "props" min-content / #{$pM_frame_photo_width};
      }
      &Props{
        grid-area: props;
        display: flex;
        justify-content: space-between;
        font: $pS-photoProps;
        &Item{
          max-width: 50%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
        &Title{
          text-align: right;
          width: 100%;
          color: $pm-propsC;
        }
        &Value{
          text-align: center;
          width: 100%;
          color: white;
          height: 20px;
          sup {
            font-size: 0.83em;
            vertical-align: super;
            line-height: 0;
          }
        }
      }

      &L{
        top: calc(#{$pM_frame_photo_height}/2);
        left: -3*$frame_arr_size/2;    
      }

      &R{
        top: calc(#{$pM_frame_photo_height}/2);
        right: -3*$frame_arr_size/2;
      }
    }
    &Text {
      &Block{
        grid-area: tb;
        background-color: white;
      }
      &Content{
        display: grid;
        grid-template: "photo";
      }
    }

    

  }
}