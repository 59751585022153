.licModal{
  
  &-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-imgContainer {
    box-sizing: border-box;
    position: relative;
    height: $A4_height*$A4_multiplier_big;
    width: $A4_width*$A4_multiplier_big;
    max-height: $mL-img_max_height;
    max-width: $A4_width/$A4_height*$mL-img_max_height;
    background-color: white;
    img {
      
      width: 100%;
      height: auto;
    }
  }
  &-L, &-R {
    img {
      width: auto;
      height: auto;
    }
  }

  &-L {
    top: 50%;
    left: -3*$frame_arr_size/2;    
  }
  
  &-R {
    top: 50%;
    right: -3*$frame_arr_size/2;
  }

  &-closeBlockIcon {
    background-color: rgba(#555, 0.5);
    border: 5px solid black;

    &:hover {
      background-color: black;
    }
  }

  &-description {
    width: $A4_width*$A4_multiplier_big;
    padding-top: 5px;
    border-color: transparent;
    
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

