@import '../../variables.scss';

.sM {
  &-frame {
    &Grid {
      &Arch {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 30px 1.5fr 30px 1fr 30px 79px 30px;
        grid-template-columns: 30px 1fr 30px 1fr 30px 1fr 30px;
        background-image: url('/static/Services/backgroundModal.png');
        * {
          display: grid;
          grid-template:
          "title" 79px
          "content" 1fr/1fr
        }
        &Box {          
          &1 {
            grid-area: 2/3/5/2;
          }
          &2 {
            grid-area: 2/5/5/4;
          }
          &3 {
            grid-area: 2/7/3/6;
          }
          &4 {
            grid-area: 4/7/5/6;
          }
          &5 {
            grid-area: 6/2/7/3;
          }
          &6 {
            grid-area: 6/4/7/5;
          }
          &7 {
            grid-area: 6/6/7/7;
          }
          
          &Title {
            grid-area: title;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
            text-align: center;
            background: $sM-tabTitleCbg;
            color: white;
            font: $frame-BoxTitleFont;
          }
    
          &Content {
            grid-area: content;
            padding: 10px 0 10px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: white;            
          }
          
          &Row {
            width: 100%;
            display: grid;
            grid-template: "mark text" 1fr/ 25px 1fr;
          }
    
          &MarkerBox {
            grid-area: mark;
            display: flex;
            align-items: flex-start;
          }
    
          &Marker {
            display: block;
            box-sizing: border-box;
            margin: 6px 0px 0 0px;
            background-color: $sM-markerTitleCbg;
            width: 19px;
            height: 9px;
          }
    
          &Text {
            display: block;
            grid-area: text;
            text-align: left;
            font: $frame-BoxTextFont;
          }
        }        
      } 
    }
  }
}