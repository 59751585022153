.cS {

  
  &-employees{
    grid-area: con;
    position: relative;
    width: 720px;
    height: 100%;

    

    .arr {
      position: absolute;
      width: $cS-empArrSize;
      height: $cS-empArrSize;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    &L {
      top: calc(#{$cS-empPhotoMainHeightN}px/2 - #{$cS-empArrSize}/2);
      left: -3*$cS-empArrSize/2;
    }

    &R {
      top: calc(#{$cS-empPhotoMainHeightN}px/2 - #{$cS-empArrSize}/2);
      right: -3*$cS-empArrSize/2;
    }

    &PhotoSubMain, &PhotoMainSub, &PhotoMainOut, &PhotoSubOut, &PhotoOut {
      position: absolute;
      width: #{$cS-empPhotoMainWidthN}px;
      height: #{$cS-empPhotoMainHeightN}px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 $cS-empPhotoBoxShadow $cS-empPhotoBoxShadow 0 rgba(0, 0, 0, 0.5);
    }

    &PhotoMainSub {
      z-index: 3;
      transition: transform 0.6s linear, opacity 0.1s linear 0.1s;
    }

    &PhotoSubMain {
      z-index: 3;
      transition: transform 0.6s linear, opacity 0.1s linear 0.5s;
    }

    &PhotoMainOut, &PhotoSubOut {
      z-index: 2;
      transition: 0.6s linear;
    }

    &PhotoOut {
      z-index: 1;
      transition: 0.6s linear;
    }

    &Title {
      position: absolute;
      top: #{$cS-empPhotoMainHeightN}px;
      width: #{$cS-empPhotoMainWidthN}px;
      height: 100px;
      left: 150px;
      transition: 0.6s;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      * {
        margin-bottom: $cS-titleMarginBottom;
        color: $cS-infoCTxt;
        text-align: center;
      }
    }



    &Name {
      font-size: $cS-nameFontSize;
    }

    &Job {
      font-size: $cS-jobFontSize;

    }

  }


}