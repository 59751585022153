@import '../variables.scss';

.cS {
  &-employees {
    &Desc {
      position: absolute;
      right: -480px;
      top: 0;
      height: 100%;
      width: calc(450px - 63px);
      font-size: 1.07em;

      &Row {
        width: 100%;
        display: grid;
        grid-template: "mark text" 1fr/ 40px 1fr;
      }

      &MarkerBox {
        grid-area: mark;
        display: flex;
        align-items: flex-start;
        
        position: relative;
      }

      &Marker {
        box-sizing: border-box;
        width: 100%;
        height: 1.405em;
        margin: 0 5px 0 0;
        

      }

      &Text {
        display: block;
        grid-area: text;
        text-align: left;
        font: $pM-frameTextBlockFont;
      }
    }
  }
}